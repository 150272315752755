import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <button id="go" onClick={
      (e)=>{
        e.preventDefault();
        // Use the same service UUID as in mbed here!
        navigator.bluetooth.requestDevice({ filters: [{ services: [ 0x8765 ] }] })
          .then(device => {
            console.log('Found device ' + device.name);
            return device.connectGATT();
          })
          .then(() =>{
            alert('Connected over GATT!');
          })
          .catch(err => {
            alert('' + err + ' ' + JSON.stringify(err));
          });
      }
    }
    >Go Bluetooth</button>
  );
}

export default App;
